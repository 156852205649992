export default {
    obterEstrutura() {
        return new Promise((resolve) => {
            const estruturaReceitas = [
                {
                    id: 1,
                    grupoDRE_pai: null,
                    codigoDRE: "1",
                    descricaoDRE: "Receitas",
                    nivelDRE: 1,
                    subgrupo: [
                        {
                            id: 2,
                            grupoDRE_pai: 1,
                            codigoDRE: "01.01",
                            descricaoDRE: "Venda",
                            nivelDRE: 2,
                            categoriaDRE: [
                                {
                                    id: 1,
                                    grupoDRE_id: 2,
                                    codigo: null,
                                    descricao: "Cases para Smartphones",
                                    familia: true,
                                    categoriaItem: [
                                        {
                                            id: 1,
                                            nome: "Cases de Plástico",
                                        },
                                        {
                                            id: 2,
                                            nome: "Cases de Silicone",
                                        },
                                    ],
                                },
                                {
                                    id: 2,
                                    grupoDRE_id: 2,
                                    codigo: null,
                                    descricao: "Cases para Tablets",
                                    familia: true, 
                                    categoriaItem: [
                                        {
                                            id: 3,
                                            nome: "Cases Rígidos",
                                        },
                                        {
                                            id: 4,
                                            nome: "Cases Flexíveis",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 3,
                            grupoDRE_pai: 1,
                            codigoDRE: "01.02",
                            descricaoDRE: "Revenda",
                            nivelDRE: 2,
                            categoriaDRE: [
                                {
                                    id: 3,
                                    grupoDRE_id: 3,
                                    codigo: null,
                                    descricao: "Acessórios Complementares",
                                    familia: true, 
                                    categoriaItem: [
                                        {
                                            id: 5,
                                            nome: "Películas de Vidro",
                                        },
                                        {
                                            id: 6,
                                            nome: "Suportes de Mesa",
                                        },
                                    ],
                                },
                                {
                                    id: 4,
                                    grupoDRE_id: 3,
                                    codigo: null,
                                    descricao: "Embalagens Personalizadas",
                                    familia: true, 
                                    categoriaItem: [
                                        {
                                            id: 7,
                                            nome: "Caixas com Logo",
                                        },
                                        {
                                            id: 8,
                                            nome: "Envelopes Personalizados",
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            id: 4,
                            grupoDRE_pai: 1,
                            codigoDRE: "01.03",
                            descricaoDRE: "Serviços Prestados",
                            nivelDRE: 2,
                            categoriaDRE: [
                                {
                                    id: 5,
                                    grupoDRE_id: 4,
                                    codigo: null,
                                    descricao: "Personalização de Cases",
                                    familia: true,
                                    categoriaItem: [
                                        {
                                            id: 9,
                                            nome: "Impressão UV",
                                        },
                                        {
                                            id: 10,
                                            nome: "Gravação a Laser",
                                        },
                                    ],
                                },
                                {
                                    id: 6,
                                    grupoDRE_id: 4,
                                    codigo: null,
                                    descricao: "Consultoria em Design de Cases",
                                    familia: false, 
                                    categoriaItem: [],
                                },
                            ],
                        },
                        {
                            id: 5,
                            grupoDRE_pai: 1,
                            codigoDRE: "01.04",
                            descricaoDRE: "Locação",
                            nivelDRE: 2,
                            categoriaDRE: [
                                {
                                    id: 7,
                                    grupoDRE_id: 5,
                                    codigo: null,
                                    descricao: "Locação de Equipamentos",
                                    familia: false, 
                                    categoriaItem: [
                                        {
                                            id: 11,
                                            nome: "Máquinas de Corte",
                                        },
                                        {
                                            id: 12,
                                            nome: "Impressoras UV",
                                        },
                                    ],
                                },
                                {
                                    id: 8,
                                    grupoDRE_id: 5,
                                    codigo: null,
                                    descricao: "Locação de Espaço",
                                    familia: false, 
                                    categoriaItem: [],
                                },
                            ],
                        },
                    ],
                },
            ];

            resolve({ data: estruturaReceitas });
        });
    },
};
