<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Classes de Receitas</h2>
            </div>
        </div>
        <div class="margem container">
            <div class="submit m-b">
                <button @click="abrirModalCadastro">Cadastrar Classe</button>
            </div>
            <div class="bloco margem">
                <table class="tabela ">
                    <thead>
                        <tr>
                            <th style="cursor: pointer;">Código</th>
                            <th style="cursor: pointer;">Receita</th>
                            <th style="cursor: pointer;">Ações</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <template v-for="item in receitas" :key="item.id">
                            <tr>
                                <td>{{ item.codigoDRE }}</td>
                                <td><b>{{ item.descricaoDRE }}</b></td>
                                <td style="display: flex; ">
                                    <a style=" transform: scale(0.8)" class="icone-adicionar"
                                        @click="abrirModalAdicionarSubgrupo(item)"></a>
                                    <a style="transform: scale(0.8)" class="icone-editar"
                                        @click="abrirModalEditar(item)" title="Editar Receita">
                                    </a>
                                    <a class="icone-lixeira" @click="abrirModalExcluir(item)" title="Excluir Receita">
                                    </a>
                                </td>
                            </tr>
                            <template v-if="item.categoriaDRE && item.categoriaDRE.length">
                                <tr v-for="categoria in item.categoriaDRE" :key="'categoria-' + categoria.id">
                                    <td></td>
                                    <td style="padding-left: 20px;">- {{ categoria.descricao }}</td>
                                    <td style="display: flex; ">
                                        <a style="transform: scale(0.8)" class="icone-editar"
                                            @click="abrirModalEditarCategoria(item, categoria)"
                                            title="Editar Categoria">
                                        </a>
                                        <a class="icone-lixeira" @click="abrirModalExcluirCategoria(item, categoria)"
                                            title="Excluir Categoria">
                                        </a>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- MODAL CADASTRAR SUBGRUPO -->
        <div v-if="showSubgrupoModal" class="modal-mask" @click="fecharModalFora">
            <div class="modal-container" style="height: min-content; width: 50rem;">
                <div class="modal-header">
                    <h3 class="modal-title fs-5">{{ subgrupoModal.titulo }}</h3>
                    <span class="close" @click="fecharModalSubgrupo">&times;</span>
                </div>
                <div class="modal-body">
                    <div>
                        <label for="descricaoSubgrupo">Descrição</label>
                        <input type="text" id="descricaoSubgrupo" v-model="subgrupoModal.descricao" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="salvarSubgrupo">Salvar</button>&nbsp;&nbsp; <button @click="fecharModalSubgrupo"
                        class="acao-secundaria">Cancelar</button>
                </div>
            </div>
        </div>
        <!-- END MODAL CADASTRAR SUBGRUPO -->
        <!-- MODAL ADD RECEITA -->
        <div class="modal-mask" v-if="showCadastroModal" @click="fecharModalFora">
            <div class="modal-container" style="height: min-content; width: 50rem;">
                <div class="modal-header">
                    <h3 class="modal-title fs-5">Cadastrar Classe de Receita</h3>
                    <span class="close" @click="fecharModalCadastro">&times;</span>
                </div>
                <div class="modal-body">
                    <div>
                        <label for="codigo">Código</label>
                        <input type="text" v-model="novaReceita.codigoDRE" id="codigo" />
                    </div>
                    <div>
                        <label for="descricao">Nome da Receita</label>
                        <input type="text" v-model="novaReceita.descricaoDRE" id="descricao" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="salvarCadastro">Salvar</button>&nbsp;&nbsp; <button class="acao-secundaria"
                        @click="fecharModalCadastro">Cancelar</button>
                </div>
            </div>
        </div>
        <!--END MODAL ADD RECEITA -->
        <!-- MODAL EDIÇÃO -->
        <div class="modal-mask" v-if="showEditarModal" @click="fecharModalFora">
            <div class="modal-container" style="height: min-content; width: 50rem;">
                <div class="modal-header">
                    <h3 class="modal-title fs-5">Editar Classe de Receita</h3>
                    <span class="close" @click="fecharModalEditar">&times;</span>
                </div>
                <div class="modal-body">
                    <div>
                        <label for="codigo">Código</label>
                        <input type="text" v-model="receitaEditar.codigoDRE" id="codigo" />
                    </div>
                    <div>
                        <label for="descricao">Nome da Receita</label>
                        <input type="text" v-model="receitaEditar.descricaoDRE" id="descricao" />
                    </div>
                </div>
                <div class="modal-footer">
                    <button @click="salvarEdicao">Salvar</button>&nbsp;&nbsp; <button class="acao-secundaria"
                        @click="fecharModalEditar">Cancelar</button>
                </div>
            </div>
        </div>
        <!-- END MODAL EDIÇÃO -->
        <!-- MODAL CATEGORIA -->
        <div class="modal-mask" v-if="showCategoriaModal" @click="fecharModalFora">
            <div class="modal-container">
                <h3>{{ categoriaModal.titulo }}</h3>
                <div class="modal-body">
                    <label for="descricaoCategoria">Descrição da Categoria</label>
                    <input type="text" v-model="categoriaModal.categoria.descricao" id="descricaoCategoria" />
                </div>
                <div class="modal-footer">
                    <button @click="categoriaModal.acao">Salvar</button>&nbsp;&nbsp; <button class="acao-secundaria"
                        @click="fecharModalCategoria">Cancelar</button>
                </div>
            </div>
        </div>
        <!--END MODAL CATEGORIA -->
        <!-- MODAL EXCLUIR  -->
        <div class="modal-mask" v-if="showExcluirModal" @click="fecharModalFora">
            <div class="jm margem" style="height: min-content; width: 20rem;">
                <div style="display: flex; justify-content: center"></div>
                <div class="modal-body">
                    <span>Confirma a exclusão de <strong>{{ receitaExcluir.descricaoDRE }}</strong>?</span>
                    <div class="modal-footer">
                        <br><br>
                        <button @click="confirmarExclusao">Confirmar</button>&nbsp;&nbsp; <button
                            class="acao-secundaria" @click="fecharModalExcluir">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MODAL EXCLUIR -->
    </section>
</template>
<script>
import receitaService from "../../services/receita-service";

export default {
    data() {
        return {
            receitas: [],
            showCadastroModal: false,
            showExcluirModal: false,
            showEditarModal: false,
            novaReceita: { codigoDRE: '', descricaoDRE: '' },
            receitaExcluir: null,
            receitaEditar: null,
            receitaAtual: null,
            categoriaModal: {
                titulo: '',
                categoria: { descricao: '' },
                acao: null,
            },
            showSubgrupoModal: false,
            subgrupoModal: {
                titulo: '',
                descricao: '',
                grupoAtual: null,
            },
        };
    },
    mounted() {
        this.getAllReceitas()
    },
    methods: {
        abrirModalAdicionarSubgrupo(grupo) {
            this.subgrupoModal = {
                titulo: 'Cadastrar Subclasse',
                descricao: '',
                grupoAtual: grupo,
            };
            this.showSubgrupoModal = true;
        },
        async salvarSubgrupo() {
            try {
                const novoSubgrupo = await receitaService.criarGrupoEstrutura({
                    dre_pai: this.subgrupoModal.grupoAtual.id,
                    desc: this.subgrupoModal.descricao,
                });
                this.subgrupoModal.grupoAtual.subgrupo.push(novoSubgrupo.data);
                this.fecharModalSubgrupo();
            } catch (error) {
                console.error('Erro ao salvar subgrupo:', error);
            }
        },
        fecharModalSubgrupo() {
            this.showSubgrupoModal = false;
            this.subgrupoModal = {
                titulo: '',
                descricao: '',
                grupoAtual: null,
            };
        },
        async getAllReceitas() {
            try {
                const response = await receitaService.obterEstrutura();
                this.receitas = response.data[0]?.subgrupo.map(subgrupo => ({
                    ...subgrupo,
                    categoriaDRE: subgrupo.categoriaDRE || [],
                })) || [];
            } catch (error) {
                console.error("Erro ao carregar receitas", error);
            }
        },


        abrirModalAdicionarCategoria(receita) {
            this.receitaAtual = receita;
            this.categoriaModal = {
                titulo: 'Adicionar Categoria',
                categoria: { descricao: '' },
                acao: this.adicionarCategoria,
            };
            this.showCategoriaModal = true;
        },
        abrirModalEditarCategoria(receita, categoria) {
            this.receitaAtual = receita;
            this.categoriaModal = {
                titulo: 'Editar Categoria',
                categoria: { ...categoria },
                acao: this.editarCategoria,
            };
            this.showCategoriaModal = true;
        },
        abrirModalExcluirCategoria(receita, categoria) {
            receita.categoriaDRE = receita.categoriaDRE.filter(cat => cat.id !== categoria.id);
        },
        adicionarCategoria() {
            if (!this.receitaAtual.categoriaDRE) {
                this.receitaAtual.categoriaDRE = [];
            }
            this.receitaAtual.categoriaDRE.push({
                ...this.categoriaModal.categoria,
                id: Date.now(),
            });
            this.fecharModalCategoria();
        },

        editarCategoria() {
            const index = this.receitaAtual.categoriaDRE.findIndex(
                cat => cat.id === this.categoriaModal.categoria.id
            );
            if (index !== -1) {
                this.receitaAtual.categoriaDRE.splice(index, 1, this.categoriaModal.categoria);
            }
            this.fecharModalCategoria();
        },
        fecharModalCategoria() {
            this.showCategoriaModal = false;
        },

        abrirModalCadastro() {
            this.novaReceita = { codigoDRE: '', descricaoDRE: '', categoriaDRE: [] };
            this.showCadastroModal = true;
        },
        salvarCadastro() {
            this.receitas.push({ ...this.novaReceita, id: Date.now() });
            this.fecharModalCadastro();
        },
        fecharModalCadastro() {
            this.showCadastroModal = false;
        },
        abrirModalExcluir(item) {
            this.receitaExcluir = item;
            this.showExcluirModal = true;
        },
        confirmarExclusao() {
            this.receitas = this.receitas.filter(r => r.id !== this.receitaExcluir.id);
            this.fecharModalExcluir();
        },
        fecharModalExcluir() {
            this.showExcluirModal = false;
        },
        abrirModalEditar(item) {
            this.receitaEditar = { ...item };
            this.showEditarModal = true;
        },
        salvarEdicao() {
            const index = this.receitas.findIndex(r => r.id === this.receitaEditar.id);
            if (index !== -1) this.receitas.splice(index, 1, this.receitaEditar);
            this.fecharModalEditar();
        },
        fecharModalEditar() {
            this.showEditarModal = false;
        },
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.fecharModalCadastro();
                this.fecharModalExcluir();
                this.fecharModalEditar();
                this.fecharModalCategoria();
                this.fecharModalSubgrupo();
            }
        },


    }
};
</script>
<style scoped>
.modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-container {
    background-color: var(--cor-bg);
    margin: 5% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
    max-height: 90%;
    overflow-y: auto;
    position: relative;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-bottom: 10px;
    margin-bottom: 20px;
}

.close {
    color: #6e6b6b;
    font-size: 1.5em;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}

.modal-body {
    margin-bottom: 20px;
}

.modal-excluir {
    width: 30rem;
    max-width: 90%;
    padding: 1.5rem;
    text-align: center;
}

.modal-excluir h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.modal-excluir .modal-body {
    margin-bottom: 1rem;
    font-size: 1rem;
}

.modal-excluir .modal-footer {
    display: flex;
    justify-content: center;
    gap: 1rem;
}
</style>
