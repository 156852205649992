<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Cadastrar Receitas</h2>
            </div>
        </div>
        <div class="margem">
            <div class="bloco2 margem" style="background-color: var(--cor-bg); cursor: pointer;">
                <div class="grid margem">
                    <label>Ano / Exercício:</label>
                    <select style="width: 200px; padding: 5px;" v-model="anoSelecionado">
                        <option value="" disabled>Selecione</option>
                        <option v-for="item in anos" :key="item.ano" :value="item.ano">{{ item.ano }}</option>
                    </select>&nbsp; <i title="Clique para adicionar Ano / Exercício" class="fa-solid fa-circle-plus"
                        style="color: var(--cor-sucesso);" @click="abrirModal"></i>
                </div>
                <div class="grid margem tags m-b">
                    <a @click="item"
                        :class="{ ativo: categoriasFiltradas.some(cat => cat.categoriaItem.length > 0) }">Item</a>
                    <a @click="familia"
                        :class="{ ativo: categoriasFiltradas.every(cat => cat.categoriaItem.length === 0) }">Família</a>
                </div>
                <table class="tabela">
                    <thead>
                        <tr>
                            <th class="header-fixed">Categoria</th>
                            <th class="header-fixed">Valor Previsto</th>
                            <th class="header-fixed">Saldo</th>
                            <th v-for="mes in meses" :key="mes" class="header-fixed">{{ mes }}</th>
                            <th class="header-fixed">Valor Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(categoria, catIndex) in categoriasFiltradas" :key="categoria.id">
                            <td class="categoria-coluna">{{ categoria.descricao }}</td>
                            <td>
                                <money3
                                    style="font-size: smaller; color: var(--cor-fonte-forte); border: 1px solid var(--cor-separador); border-radius: 6px;"
                                    class="tamanho-unificado tamanho-input alinha-centro"
                                    v-model="categoria.valor_previsto" v-bind="config"
                                    @blur="atualizarReceita(categoria)"></money3>
                            </td>
                            <td>
                                <money3 class="tamanho-unificado tamanho-input alinha-centro"
                                    style="font-weight: bold; color: var(--cor-sucesso); border: none;  border-radius: 6px;"
                                    :model-value="calcularSaldo(catIndex)" v-bind="config" disabled="true"></money3>
                            </td>
                            <td v-for="(mes, mesIndex) in meses" :key="mesIndex">
                                <money3
                                    style="font-size: smaller; color: var(--cor-fonte-forte);  border: 1px solid var(--cor-separador); border-radius: 6px;"
                                    class="tamanho-unificado tamanho-input alinha-centro" v-bind="config"
                                    model=" categoriaValores[catIndex][mes]"
                                    :class="{ 'input-error': categoriaErros[catIndex][mes] }"
                                    :disabled="!isEditable(mesIndex)"
                                    @blur="verificarValor(categoria.id, mesIndex, catIndex, categoriaValores[catIndex][mes], $event)">
                                </money3>
                            </td>
                            <td>
                                <money3
                                    style="font-weight: bold; color: var(--cor-fonte-forte) !important; border: 1px solid var(--cor-separador); border-radius: 6px; border: none;"
                                    class="tamanho-unificado tamanho-input alinha-centro"
                                    :model-value="calcularTotal(catIndex)" v-bind="config" disabled="true"></money3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- MODAL ANO/EXERCICIO -->
        <div class="modal-mask" v-if="modalArea" @click="fecharModalFora">
            <div class="modal-container" style="height: min-content; width: 50rem;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5">Adicionar Ano / Exercício:</h3>
                    </div>
                    <div class="modal-body">
                        <input style="width: 200px; padding: 5px;" type="text" v-model="ano" placeholder="" />&nbsp;
                        <button style="padding: 5px 10px;" @click="adicionarAno">Adicionar</button>
                        <br><br>
                        <div class="bloco2 margem2" style="overflow: auto; max-height: 15rem;">
                            <br>
                            <ul>
                                <li v-for="ano in anos" :key="ano.id"> {{ ano.ano }} <span @click="excluirAno(ano.id)"
                                        @mouseover="mostrarOuOcultarBotao(ano.id, 'Ano', true)"
                                        @mouseleave="mostrarOuOcultarBotao(ano.id, 'Ano', false)" class="icon-trash">
                                        <i class="fa-solid fa-trash" :id="'apagarAno' + ano.id"
                                            style="color: var(--cor-erro); opacity: 0;"></i>
                                    </span>
                                    <br>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br>
                <div class="modal-footer alinha-direita">
                    <!-- <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button> -->
                </div>
            </div>
        </div>
        <!-- END MODAL ANO/EXERCICIO -->
    </section>
</template>
<script>
import receitaService from "@/services/receita-service";
import anoexercicioService from '@/services/ano-exercicio-service';
import { Money3Component } from "v-money3";
import { createToaster } from "@meforma/vue-toaster";

const toaster = createToaster({
    position: "top-right",
    duration: 4000,
});

export default {
    components: {
        money3: Money3Component,
    },
    data() {
        return {
            config: {
                masked: false,
                prefix: "R$ ",
                thousands: ".",
                decimal: ",",
                precision: 2,
            },
            meses: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun", "Jul", "Ago", "Set", "Out", "Nov", "Dez"],
            categorias: [],
            categoriasFiltradas: [],
            categoriaValores: [],
            categoriaErros: [],
            anos: [],
            anoSelecionado: "",
            ano: "",
            modalArea: false,
        };
    },
    computed: {
        mesAtual() {
            return new Date().getMonth();
        },
        anoAtual() {
            return new Date().getFullYear();
        },
        isFutureYear() {
            return this.anoSelecionado > this.anoAtual;
        },
    },
    mounted() {
        this.obterAnos();
        this.obterEstruturaReceitas();
    },
    methods: {

        async item() {
            this.categoriasFiltradas = this.categorias.filter(categoria =>
                categoria.categoriaItem && categoria.categoriaItem.length > 0
            );
        },

        async familia() {
            this.categoriasFiltradas = this.categorias.filter(categoria =>
                categoria.categoriaItem && categoria.categoriaItem.length === 0
            );
        },
        mostrarOuOcultarBotao(id, type, show) {
            const elementId = type === 'Ano' ? `apagarAno${id}` : `apagar${type}${id}`;
            const element = document.getElementById(elementId);
            if (element) {
                element.style.opacity = show ? 1 : 0;
            }
        },
        async obterEstruturaReceitas() {
            try {
                const response = await receitaService.obterEstrutura();
                const estrutura = response.data;

                this.categorias = [];
                this.categoriaValores = [];
                this.categoriaErros = [];

                estrutura.forEach(grupo => {
                    grupo.subgrupo.forEach(subgrupo => {
                        subgrupo.categoriaDRE.forEach(categoria => {
                            this.categorias.push(categoria);
                            const valores = {};
                            const erros = {};
                            this.meses.forEach(mes => {
                                valores[mes] = 0;
                                erros[mes] = false;
                            });
                            this.categoriaValores.push(valores);
                            this.categoriaErros.push(erros);
                        });
                    });
                });

                this.categoriasFiltradas = [...this.categorias];
            } catch (error) {
                console.error("Erro ao obter estrutura de receitas:", error);
                toaster.error("Erro ao carregar estrutura de receitas.");
            }
        },
        async obterAnos() {
            try {
                const response = await anoexercicioService.obterAnoExercicio();
                this.anos = response.data;
                this.anoSelecionado = this.anoAtual;
                this.obterDados();
            } catch (error) {
                console.error('Erro ao obter anos:', error);
            }
        },
        calcularSaldo(catIndex) {
            const categoria = this.categoriasFiltradas[catIndex];
            const valores = this.categoriaValores[catIndex];
            const total = this.meses.reduce((sum, mes) => sum + parseFloat(valores[mes] || 0), 0);
            return (parseFloat(categoria.valor_previsto) - total).toFixed(2);
        },
        calcularTotal(catIndex) {
            const valores = this.categoriaValores[catIndex];
            return this.meses.reduce((sum, mes) => sum + parseFloat(valores[mes] || 0), 0).toFixed(2);
        },
        async atualizarReceita(categoria) {
            toaster.info(`Receita atualizada: ${categoria.descricao}`);
        },
        verificarValor(categoriaId, mesIndex, catIndex, valor, event) {
            if (valor < 0) {
                toaster.error("O valor não pode ser negativo.");
                event.target.focus();
            }
        },
        abrirModal() {
            this.modalArea = true;
        },
        fecharModalFora(event) {
            if (event.target.classList.contains("modal-mask")) {
                this.modalArea = false;
            }
        },
        adicionarAno() {
            if (!this.ano) {
                toaster.error("Ano inválido.");
                return;
            }
            this.anos.push({ ano: this.ano });
            this.modalArea = false;
            this.ano = "";
        },
        isEditable(mesIndex) {
            return this.anoSelecionado >= this.anoAtual && mesIndex >= this.mesAtual;
        },
    },
};
</script>
<style scoped>
.tamanho-input {
    width: 100px;
    font-size: 0.8em;
    padding: 2px;

}

.tamanho-unificado {
    width: 100px;
    padding: 1px;
}

.header-fixed {
    position: sticky !important;
    top: 0 !important;
    z-index: 999 !important;
    background-color: var(--cor-bg) !important;
    text-align: center !important;
}

.input-error {
    border-color: var(--cor-erro);
    color: var(--cor-erro);
}

.table-container {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
}
</style>
