<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <h2>Relatório Receitas</h2>
            </div>
        </div>
        <div class="margem container">
            <div class="margem container" style="cursor: pointer;">
                <div class="tags m-b">
                    <a @click="orcamentos" class="">Orçamentos</a>
                    <a @click="geral" class="">Relatório Orçamento</a>
                    <a @click="dashboard" class="ativo">Relatório Receitas</a>
                </div>
                <div class="grid-4 bloco2 margem">
                    <div>
                        <label> Ano / Exercício: </label>
                        <select v-model="anoSelecionado">
                            <option value="" disabled>Selecione</option>
                            <option v-for="item in anos" :key="item.ano" :value="item.ano"> {{ item.ano }} </option>
                        </select>
                    </div>
                </div>
                <br>
                <div class="bloco margem">
                    <table class="tabela">
                        <thead>
                            <tr>
                                <th>Receita </th>
                                <th>Valor Previsto</th>
                                <th>Valor Realizado</th>
                            </tr>
                        <tbody>
                            <template v-for="(receita, index) in receitas" :key="index">
                                <tr>
                                    <td @click="toggleExpandir(receita)">
                                        <b>
                                            <i class="fa-regular"
                                                :class="{ 'fa-square-caret-down': receita.expandido, 'fa-square-caret-right': !receita.expandido }"
                                                style="cursor: pointer;"></i> {{ receita.descricaoDRE }} </b>
                                    </td>
                                    <td>
                                        <money3
                                            style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);"
                                            :disabled="true" v-model="receita.total_previsto" v-bind="config">
                                        </money3>
                                    </td>
                                    <td>
                                        <money3
                                            style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);"
                                            :disabled="true" v-model="receita.total_realizado" v-bind="config">
                                        </money3>
                                    </td>
                                </tr>
                                <template v-for="(subgrupo, subindex) in receita.subgrupo" :key="subindex">
                                    <tr v-show="receita.expandido">
                                        <td class="subgrupo" @click="toggleExpandir(subgrupo)">
                                            <b>
                                                <i class="fa-regular"
                                                    :class="{ 'fa-square-caret-down': subgrupo.expandido, 'fa-square-caret-right': !subgrupo.expandido }"
                                                    style="cursor: pointer;"></i> {{ subgrupo.descricaoDRE }} </b>
                                        </td>
                                        <td>
                                            <money3
                                                style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);"
                                                :disabled="true" v-model="subgrupo.total_previsto" v-bind="config">
                                            </money3>
                                        </td>
                                        <td>
                                            <money3
                                                style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);"
                                                :disabled="true" v-model="subgrupo.total_realizado" v-bind="config">
                                            </money3>
                                        </td>
                                    </tr>
                                    <tr v-show="subgrupo.expandido"
                                        v-for="(categoria, catIndex) in subgrupo.categoriaDRE" :key="catIndex">
                                        <td>{{ categoria.descricao }}</td>
                                        <td>
                                            <money3
                                                style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);"
                                                :disabled="true" v-model="categoria.total_previsto" v-bind="config">
                                            </money3>
                                        </td>
                                        <td>
                                            <money3
                                                style="border: none; opacity: 1; background-color: transparent; color: var(--cor-fonte-forte);"
                                                :disabled="true" v-model="categoria.valor_realizado" v-bind="config">
                                            </money3>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                        </thead>
                    </table>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import { Money3Component } from 'v-money3';
import anoexercicioService from '@/services/ano-exercicio-service';
import receitaService from "../../services/receita-service";


export default {
    components: {
        money3: Money3Component,
    },
    data() {
        const currentYear = new Date().getFullYear();

        return {
            anos: [],
            anoSelecionado: currentYear,
            config: {
                masked: false,
                prefix: 'R$ ',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },
            receitas: []
        }
    },

    methods: {
        toggleExpandir(item) {
            item.expandido = !item.expandido;
        },
        async getAllReceitas() {
            try {
                const response = await receitaService.obterEstrutura();
                this.receitas = response.data[0]?.subgrupo.map(subgrupo => ({
                    ...subgrupo,
                    categoriaDRE: subgrupo.categoriaDRE || [],
                })) || [];
            } catch (error) {
                console.error("Erro ao carregar receitas", error);
            }
        },
        async obterAnos() {
            try {
                const response = await anoexercicioService.obterAnoExercicio();
                this.anos = response.data;
            } catch (error) {
                console.error('Erro ao obter anos:', error);
            }
        },
        orcamentos() {
            this.$router.push('/relatorio/orcamento');
        },

        geral() {
            this.$router.push('/relatorio/geral');

        },
        dashboard() {
            this.$router.push('/relatorio/dashboard');

        },
    },
    watch: {
        anoSelecionado(newAno) {
            this.obterEstrutura(newAno);
        }
    },
    mounted() {
        this.obterAnos();
        this.getAllReceitas();

    },

}



</script>