<template>
    <section>
        <div class="titulo">
            <div class="margem container">
                <div class="m-icone direita">
                    <div class="pesquisa">
                        <input v-model="termoPesquisa" @input="filtrarCategorias" type="text"
                            placeholder="Pesquisar categoria por nome" />
                        <a class="icone-pesquisa" title="Pesquise"></a>
                    </div>
                </div>
                <h2>Orçamento Manual</h2>
            </div>
        </div>
        <div class="margem ">
            <div class="bloco2 margem" style="background-color: var(--cor-bg); cursor: pointer;">
                <div class="tags m-b">
                    <a @click="manual" class="ativo">Manual</a>
                    <a @click="planilha" class="">Planilha</a>
                </div>
                <div class="grid margem">
                    <label>Ano / Exercício:</label>
                    <select style="width: 200px; padding: 5px;" v-model="anoSelecionado">
                        <option value="" disabled>Selecione</option>
                        <option v-for="item in anos" :key="item.ano" :value="item.ano"> {{ item.ano }} </option>
                    </select>&nbsp; <i title="Clique para adicionar Ano / Exercício" class="fa-solid fa-circle-plus"
                        style="color: var(--cor-sucesso);" @click="abrirModal"></i>&nbsp;&nbsp; <button
                        style="padding: 9px;" @click="abrirModalPlanilha">Gerar Planilha </button>
                </div>
                <table class="tabela">
                    <thead>
                        <tr>
                            <th class="header-fixed">Categoria</th>
                            <th class="header-fixed">Valor Previsto</th>
                            <th class="header-fixed">Saldo</th>
                            <th v-for="mes in meses" :key="mes" class="header-fixed">{{ mes }}</th>
                            <th class="header-fixed">Valor Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(categoria, catIndex) in categoriasFiltradas" :key="categoria.id">
                            <td class="categoria-coluna">{{ categoria.descricao }}</td>
                            <td>
                                <money3
                                    style="font-size: smaller  ;opacity: 1; color: var(--cor-fonte-forte); border: 1px solid var(--cor-separador); border-radius: 6px;"
                                    class="tamanho-unificado tamanho-input alinha-centro"
                                    v-model="categoria.valor_previsto" v-bind="config"
                                    @blur="atualizarOrcamento(categoria)"></money3>
                            </td>
                            <td>
                                <money3 class="tamanho-unificado tamanho-input alinha-centro"
                                    style="font-weight: bold ;font-size: small  ;color: var(--cor-sucesso); border: none; border-radius: 6px;"
                                    :model-value="calcularSaldo(catIndex)" v-bind="config" disabled="true"></money3>
                            </td>
                            <td v-for="(mes, mesIndex) in meses" :key="mesIndex">
                                <money3
                                    style="font-size: smaller  ;color: var(--cor-fonte-forte); border: 1px solid var(--cor-separador); border-radius: 6px;"
                                    class="tamanho-unificado tamanho-input alinha-centro" v-bind="config"
                                    v-model="categoriaValores[catIndex][mes]"
                                    :class="{ 'input-error': categoriaErros[catIndex][mes] }"
                                    :disabled="!isEditable(mesIndex)"
                                    @blur="verificarValor(categoria.id, mesIndex, catIndex, categoriaValores[catIndex][mes], $event)">
                                </money3>
                            </td>
                            <td>
                                <money3
                                    style="font-weight: bold;   font-size: smaller; color: var(--cor-fonte-forte) !important; border: 1px solid var(--cor-separador); border-radius: 6px; border: none;"
                                    class="tamanho-unificado tamanho-input alinha-centro"
                                    :model-value="calcularTotal(catIndex)" v-bind="config" disabled="true"></money3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <!-- MODAL NOME DO ARQUIVO -->
        <div v-if="modalPlanilha" class="modal-mask" @click="fecharModalPlanilhaFora">
            <div class="modal-container" style="max-width: 25%; max-height: 20%;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5">Definir Nome do Arquivo:</h3>
                    </div>
                    <br>
                    <div class="modal-body">
                        <input type="text" v-model="nomeArquivo" placeholder="Nome do arquivo" />
                    </div>
                    <br>
                    <div class="modal-footer">
                        <button type="button" class="button-cadastro"
                            @click="confirmarExportarPlanilha">Salvar</button>&nbsp;&nbsp; <button type="button"
                            @click="fecharModal" class="acao-secundaria">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- END MODAL NOME DO ARQUIVO -->
        <!-- MODAL ANO/EXERCICIO -->
        <div class="modal-mask" v-if="modalArea" @click="fecharModalFora">
            <div class="modal-container" style="height: min-content; width: 50rem;">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="modal-title fs-5">Adicionar Ano / Exercício:</h3>
                    </div>
                    <div class="modal-body">
                        <input style="width: 200px; padding: 5px;" type="text" v-model="ano" placeholder="" />&nbsp;
                        <button style="padding: 5px 10px;" @click="adicionarAno">Adicionar</button>
                        <br><br>
                        <div class="bloco2 margem2" style="overflow: auto; max-height: 15rem;">
                            <br>
                            <ul>
                                <li v-for="ano in anos" :key="ano.id"> {{ ano.ano }} <span @click="excluirAno(ano.id)"
                                        @mouseover="mostrarOuOcultarBotao(ano.id, 'Ano', true)"
                                        @mouseleave="mostrarOuOcultarBotao(ano.id, 'Ano', false)" class="icon-trash">
                                        <i class="fa-solid fa-trash" :id="'apagarAno' + ano.id"
                                            style="color: var(--cor-erro); opacity: 0;"></i>
                                    </span>
                                    <br>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <br>
                <div class="modal-footer alinha-direita">
                    <!-- <button type="button" @click="fecharModal" class="acao-secundaria">Cancelar</button> -->
                </div>
            </div>
        </div>
        <!-- END MODAL ANO/EXERCICIO -->
    </section>
</template>
<script>
import estruturaService from '@/services/estrutura-service';
import orcamentoService from '@/services/orcamento-service';
import anoexercicioService from '@/services/ano-exercicio-service';
import { api } from "roboflex-thalamus-request-handler";
import { Money3Component } from 'v-money3';
import * as XLSX from 'xlsx';
import { createToaster } from '@meforma/vue-toaster';

const toaster = createToaster({
    position: 'top-right',
    duration: 4000,
});

export default {
    components: {
        money3: Money3Component,
    },
    data() {
        return {
            config: {
                masked: false,
                prefix: 'R$ ',
                thousands: '.',
                decimal: ',',
                precision: 2,
            },
            meses: [
                'Jan',
                'Fev',
                'Mar',
                'Abr',
                'Mai',
                'Jun',
                'Jul',
                'Ago',
                'Set',
                'Out',
                'Nov',
                'Dez',
            ],
            modalArea: false,
            modalPlanilha: false,
            filtroAno: '',
            ano: '',
            grupos: [],
            categorias: [],
            categoriasFiltradas: [],
            categoriaValores: [],
            categoriaErros: [],
            mesAtualTeste: new Date().getMonth(),
            termoPesquisa: '',
            anos: [],
            anoSelecionado: '',
            nomeArquivo: 'orcamento',
        };
    },
    computed: {
        mesAtual() {
            const now = new Date();
            return now.getMonth();
        },
        anoAtual() {
            return new Date().getFullYear();
        },
        isFutureYear() {
            return this.anoSelecionado > this.anoAtual;
        },
    },
    mounted() {
        this.obterAnos();
    },
    methods: {
        mostrarOuOcultarBotao(id, type, show) {
            const elementId = type === 'Ano' ? `apagarAno${id}` : `apagar${type}${id}`;
            const element = document.getElementById(elementId);
            if (element) {
                element.style.opacity = show ? 1 : 0;
            }
        },
        async verificarValor(categoriaId, mesIndex, catIndex, valor, event) {
            const saldo = parseFloat(this.calcularSaldo(catIndex));
            if (parseFloat(valor) > saldo) {
                toaster.error('Valor não pode ser aceito. O valor excede o saldo disponível.');
                this.categoriaErros[catIndex][this.meses[mesIndex]] = true;
                event.target.focus();
                return;
            }
            this.categoriaErros[catIndex][this.meses[mesIndex]] = false;
            await this.atualizarOrcamentoIndividual(categoriaId, mesIndex + 1, valor);
        },
        async atualizarOrcamentoIndividual(categoriaId, mesIndex, valor) {
            try {
                const payload = {
                    categoria_id: categoriaId,
                    mes: mesIndex,
                    ano: this.anoSelecionado,
                    valor: valor,
                };
                const response = await orcamentoService.cadastroPlanilhaManual(payload);
                if (response.status === 200) {
                    if (response.data && response.data.cod === 500 && response.data.error) {
                        toaster.error(response.data.error);
                    }
                }
            } catch (error) {
                console.error('Erro ao atualizar orçamento individual:', error);
            }
        },
        async obterAnos() {
            try {
                const response = await anoexercicioService.obterAnoExercicio();
                this.anos = response.data;
                this.anoSelecionado = this.anoAtual;
                this.obterDados();
            } catch (error) {
                console.error('Erro ao obter anos:', error);
            }
        },
        async adicionarAno() {
            try {
                await anoexercicioService.cadastrarAnoExercicio({ ano: this.ano });
                this.obterAnos();
                this.ano = '';
                this.modalArea = false;
            } catch (error) {
                if (error.response && error.response.data && error.response.data.message) {
                    toaster.error(error.response.data.message);
                } else {
                    console.error('Erro ao adicionar ano:', error);
                    toaster.error('Erro ao adicionar ano.');
                }
            }
        },
        async excluirAno(id) {
            try {
                await anoexercicioService.excluirAnoExercicio(id);
                this.obterAnos();
            } catch (error) {
                console.error('Erro ao excluir ano:', error);
            }
        },

        filtrarCategorias() {
            const termo = this.termoPesquisa.toLowerCase().trim();
            if (termo) {
                this.categoriasFiltradas = this.categorias.filter(categoria =>
                    categoria.descricao.toLowerCase().includes(termo)
                );
            } else {
                this.categoriasFiltradas = [...this.categorias];
            }
        },
        manual() {
            this.$router.push('/cadastrar/orcamento/manual');
        },
        planilha() {
            this.$router.push('/cadastrar/orcamento/planilha');
        },
        abrirModal() {
            this.modalArea = true;
        },
        abrirModalPlanilha() {
            this.modalPlanilha = true;
        },
        fecharModalPlanilhaFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalPlanilha = false;
            }
        },
        fecharModalPlanilha() {
            this.modalPlanilha = false;
        },
        confirmarExportarPlanilha() {
            this.exportarPlanilha();
            this.modalPlanilha = false;
        },
        exportarPlanilha() {
            const now = new Date();
            const year = now.getFullYear();
            const month = String(now.getMonth() + 1).padStart(2, '0');
            const fileName = this.nomeArquivo ?
                `${this.nomeArquivo}.xlsx` :
                `orcamento_${year}_${month}.xlsx`;
            const ws = XLSX.utils.json_to_sheet(
                this.categoriasFiltradas.map((categoria, index) => {
                    const categoriaValores = this.categoriaValores[index];
                    const row = {
                        Categoria: categoria.descricao,
                        'Valor Previsto': categoria.valor_previsto,
                    };
                    this.meses.forEach(mes => {
                        row[mes] = categoriaValores[mes];
                    });
                    return row;
                })
            );
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Orçamento');
            XLSX.writeFile(wb, fileName);
        },
        async obterDados() {
            if (!this.anoSelecionado) {
                return;
            }

            try {
                await this.obterEstrutura(this.anoSelecionado);

                const response = await api.get('dre/categoria/listar', {
                    params: {
                        ano: this.anoSelecionado,
                    },
                });

                if (!Array.isArray(response.data)) {
                    throw new Error('Dados de resposta inválidos');
                }

                this.categorias = [];
                this.categoriasFiltradas = [];
                this.categoriaValores = [];
                this.categoriaErros = [];

                response.data.forEach(categoria => {
                    this.categorias.push(categoria);
                    this.categoriasFiltradas.push(categoria);
                    const categoriaValores = {};
                    const categoriaErro = {};
                    this.meses.forEach(mes => {
                        categoriaValores[mes] = 0;
                        categoriaErro[mes] = false;
                    });
                    categoria.meses.forEach(mes => {
                        const mesNome = this.meses[mes.mes - 1];
                        categoriaValores[mesNome] = mes.valor_previsto;
                    });
                    this.categoriaValores.push(categoriaValores);
                    this.categoriaErros.push(categoriaErro);
                });
            } catch (error) {
                console.error('Erro ao obter dados do endpoint:', error);
                toaster.error('Erro ao obter dados.');
            }
        },
        async atualizarOrcamento(categoria) {
            try {
                const payload = {
                    categoria_id: categoria.id,
                    ano: this.anoSelecionado,
                    previsto: 1,
                    valor: categoria.valor_previsto,
                };
                const response = await orcamentoService.cadastroPlanilhaManual(payload);
                console.log('Resposta do servidor:', response);
            } catch (error) {
                console.error('Erro ao atualizar orçamento:', error);
            }
        },
        calcularSaldo(catIndex) {
            const categoria = this.categoriasFiltradas[catIndex];
            const valores = this.categoriaValores[catIndex];
            const total = this.meses.reduce(
                (sum, mes) => sum + parseFloat(valores[mes] || 0),
                0
            );
            return (parseFloat(categoria.valor_previsto) - total).toFixed(2);
        },
        calcularTotal(catIndex) {
            const valores = this.categoriaValores[catIndex];
            return this.meses
                .reduce((sum, mes) => sum + parseFloat(valores[mes] || 0), 0)
                .toFixed(2);
        },
        isEditable(mesIndex) {
            if (this.anoSelecionado < this.anoAtual) {
                return false;
            }
            if (this.anoSelecionado === this.anoAtual && mesIndex < this.mesAtualTeste) {
                return false;
            }
            return true;
        },
        async obterEstrutura(ano) {
            try {
                const response = await estruturaService.obterEstrutura({ ano });
                this.grupos = response.data;
                this.obterCategorias();
            } catch (error) {
                console.error('Erro ao obter estrutura:', error);
            }
        },
        obterCategorias() {
            this.grupos.forEach(grupo => {
                grupo.subgrupo.forEach(subgrupo => {
                    subgrupo.categoriaDRE.forEach(categoria => {
                        this.categorias.push(categoria);
                        const categoriaValores = {};
                        this.meses.forEach(mes => {
                            categoriaValores[mes] = '';
                        });
                        this.categoriaValores.push(categoriaValores);
                    });
                });
            });
        },
        fecharModalFora(event) {
            if (event.target.classList.contains('modal-mask')) {
                this.modalArea = false;
            }
        },
        fecharModal() {
            this.modalArea = false;
            this.modalPlanilha = false;
        },
        fecharLista() {
            setTimeout(() => {
                this.anoSelecionado = null;
            }, 200);
        },
        procurar(texto) {
            this.listaPessoasFiltrada = this.gerente;
            this.listaPessoasFiltrada = this.listaPessoasFiltrada.filter(nome =>
                nome.nomeCompleto.toLowerCase().includes(texto.toLowerCase())
            );
        },
    },
};
</script>
<style scoped>
.tamanho-input {
    width: 100px;
    font-size: 0.8em;
    padding: 2px;

}

.tamanho-unificado {
    width: 100px;
    padding: 1px;
}

.header-fixed {
    position: sticky !important;
    top: 0 !important;
    z-index: 999 !important;
    background-color: var(--cor-bg) !important;
    text-align: center !important;
}

.input-error {
    border-color: var(--cor-erro);
    color: var(--cor-erro);
}

.table-container {
    overflow-x: auto;
    overflow-y: hidden;
    max-width: 100%;
}
</style>
